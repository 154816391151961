import { prettyAddress } from "./utils.js";

export class BiMap {
    constructor(map) {
        this.keyToValue = new Map();
        this.valueToKey = new Map();
        for (const [key, value] of Object.entries(map)) {
            this.keyToValue.set(key, value);
            this.valueToKey.set(value, key);
        }
    }

    getAddress(key) {
        return this.keyToValue.get(key) || key;
    }

    getPrettyName(value, forcePretty=true, copy=true, copyIcon=true) {
        return this.valueToKey.get(value) || (forcePretty ? prettyAddress(value, copy, copyIcon) : value);
    }
}

const chainIds = new BiMap({
    'mainnet': 1n,
    'polygon-mainnet': 137n,
    'arbitrum-mainnet': 42161n,
    'arbitrum-sepolia': 421614n,
    'sepolia': 11155111n,
});

export class NetworkBiMap {
    constructor(map) {
        for (const network of Object.keys(map))
            this[chainIds.getAddress(network)] = new BiMap(map[network]);
    }
}

export default { BiMap, NetworkBiMap };
