export function prettyAddress(address, copy=true, copyIcon=true) {
    if (address === undefined || (address.length != 42 && address.length != 66))
        return address;
    const shortAddress = `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
    const copyIconHtml = copyIcon ? ' <i class="fa-solid fa-copy"></i>' : '';
    return copy ?
        `<span class="pretty-address" data-toggle="tooltip" data-placement="top" title="${address}">${shortAddress}${copyIconHtml}</span>` :
        shortAddress;
}

export default { prettyAddress };
