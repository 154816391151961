export async function getControllers(complianceContract) {
    const controller_role = await complianceContract.CONTROLLER_ROLE();
    let controllers = {};
    try {
        const complianceRoleGrantedPromise = complianceContract.queryFilter(complianceContract.filters.RoleGranted(controller_role));
        const complianceRoleRevokedPromise = complianceContract.queryFilter(complianceContract.filters.RoleRevoked(controller_role));
        const complianceRoleGranted = await complianceRoleGrantedPromise;
        const complianceRoleRevoked = await complianceRoleRevokedPromise;
        for (const event of complianceRoleGranted)
            if (!(event.args.account in controllers) || event.blockNumber > controllers[event.args.account])
                controllers[event.args.account] = event.blockNumber;
        for (const event of complianceRoleRevoked)
            if (event.args.account in controllers && event.blockNumber >= controllers[event.args.account])
                delete controllers[event.args.account];
    } catch (e) {
        console.error(e);
        return {"undefined": 0};
    }
    return controllers;
}

export default getControllers;
